import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post("https://propertybackend.myappsdevelopment.co.in/auth/register", {
        email,
        password,
      });

      if (response.data.success) {
        toast.success("Registration successful! Redirecting to login...");
        setTimeout(() => navigate("/signin"), 3000);
      } else {
        toast.error(response.data.message || "Registration failed.");
      }
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background p-4 px-[550px] py-[100px]">
      <ToastContainer />
      <img src="/logo1.png" alt="Logo" className="mb-6 w-20 h-14" />
      <h1 className="text-2xl text-zinc-800">Sign up for Fraps</h1>

      {/* Email Input */}
      <label className="mt-4 font-semibold">Email</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full mt-1 p-3 border border-zinc-300 focus:outline-none focus:ring focus:ring-primary"
      />

      {/* Password Input */}
      <label className="mt-4 font-semibold">Password</label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full mt-1 p-3 border border-zinc-300 focus:outline-none focus:ring focus:ring-primary"
        />
        <span
          className="absolute right-3 top-4 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>

      {/* Confirm Password Input */}
      <label className="mt-4 font-semibold">Confirm Password</label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="w-full mt-1 p-3 border border-zinc-300 focus:outline-none focus:ring focus:ring-primary"
        />
        <span
          className="absolute right-3 top-4 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>

      {/* Sign-Up Button */}
      <button
        onClick={handleSignUp}
        className="w-full mt-4 border border-zinc-300 bg-[#4170c4] text-white rounded-lg p-4 hover:bg-primary/80"
      >
        SIGN UP
      </button>

      <hr className="mt-9" />

      <div className="mt-6 text-sm text-black flex justify-between">
        <span className="text-2xl">Already have an account?</span>
        <Link
          to="/signin"
          className="text-primary hover:underline text-[#4170c4] font-semibold"
        >
          Sign in
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
