import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Components/Header";

// Component to display individual event details
const EventCard = ({ event }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
      <div className="relative">
        <img
          src={event.media[0]}
          alt={event.title}
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-2">{event.title}</h2>
        <p className="text-gray-700">
          <span className="font-semibold">From:</span>{" "}
          {new Date(event.from).toLocaleDateString()}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">To:</span>{" "}
          {new Date(event.to).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

// Main component to fetch and display events with filter options
const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("upcoming"); // State to manage filter
  const [page, setPage] = useState(1); // Current page
  const [eventsPerPage] = useState(10); // Number of events to show per page

  useEffect(() => {
    // Fetch events from the API
    const fetchEvents = async () => {
      try {
        const url = filter
          ? `https://propertybackend.myappsdevelopment.co.in/property/get-allevents?filter=${filter}`
          : "https://propertybackend.myappsdevelopment.co.in/property/get-allevents";

        const response = await axios.get(url);
        setEvents(response.data.data); // Set the fetched events
        setLoading(false);
      } catch (error) {
        setError("Error fetching events.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, [filter]);

  // Calculate the current events to display based on pagination
  const indexOfLastEvent = page * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  // Handle changing pages
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Calculate total pages
  const totalPages = Math.ceil(events.length / eventsPerPage);

  // Render loading state
  if (loading) return <p className="text-center text-gray-700">Loading...</p>;

  // Render error state
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div>
      <Header />
      <div className="container mx-auto p-6">
        {/* Filter buttons */}
        <div className="flex justify-center mb-6 space-x-4">
          <button
            onClick={() => setFilter("upcoming")}
            className={`px-4 py-2 rounded ${
              filter === "upcoming" ? "bg-gray-500 text-white" : "bg-gray-200"
            }`}
          >
            Upcoming Events
          </button>
          <button
            onClick={() => setFilter("completed")}
            className={`px-4 py-2 rounded ${
              filter === "completed" ? "bg-gray-500 text-white" : "bg-gray-200"
            }`}
          >
            Completed Events
          </button>
          <button
            onClick={() => setFilter("")}
            className={`px-4 py-2 rounded ${
              filter === "" ? "bg-gray-500 text-white" : "bg-gray-200"
            }`}
          >
            All Events
          </button>
        </div>

        {/* Event List */}
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
          {filter === "upcoming"
            ? "Upcoming Events"
            : filter === "completed"
            ? "Completed Events"
            : "All Events"}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentEvents.map((event) => (
            <EventCard key={event._id} event={event} />
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-6">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="px-4 py-2 bg-gray-200 rounded mr-2"
          >
            Previous
          </button>
          <span className="px-4 py-2">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="px-4 py-2 bg-gray-200 rounded ml-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventList;
