import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Headers() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log("logout1");
    try {
      await axios.get("https://propertybackend.myappsdevelopment.co.in/auth/logout", {
        withCredentials: "true",
      });

      localStorage.removeItem("token");

      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      navigate("/SignIn");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <header className="bg-whites text-white py-4 px-[200px]">
      <nav className="container mx-auto flex justify-between items-center">
        {/* Left side links */}
        <ul className="flex space-x-6 text-bold">
          <li>
            <Link to="/">
              <img
                src="/logo1.png"
                alt="daft.ie logo"
                className="h-14 mr-14 text-white"
              />
            </Link>
          </li>

          <Link to="/buy" className="hover:underline">
            Buy
          </Link>

          {/* <Link to="/Sale" className="hover:underline">
            Sale
          </Link> */}

          <Link to="/Events" className="hover:underline font-semibold">
            Events
          </Link>
        </ul>

        {/* Right side links */}
        <ul className="flex space-x-6">
          <li>
            <Link to="/SignIn" className="hover:underline">
              Sign In
            </Link>
          </li>
          <li>
            <button className="hover:underline" onClick={handleLogout}>
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Headers;
