import React from "react";

import Browser from "./Browser";
import PropertyTools from "./PropertyTools";
import PopularAreas from "./PopularAreas";
import Headers from "../Components/Headers";
import { PiClockCounterClockwise } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";

function Home() {
  return (
    <div>
      <div className="relative bg-gray-100">
        {/* Header */}
        <div className="absolute inset-x-0 top-0 z-10">
          <Headers />
        </div>

        {/* Hero Image */}
        <div className="relative h-[600px] overflow-hidden">
          <picture>
            <img
              className="w-full h-full object-cover"
              src="./image6.png"
              alt="homepage"
            />
          </picture>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
            <h1 className="text-5xl font-bold mb-4">Find your way home</h1>
            <div className="w-full max-w-4xl mx-auto">
              {" "}
              {/* Adjusted max-width and added mx-auto */}
              {/* <div className="w-full border-2 rounded-lg bg-white"> 
      <ul className="flex justify-center space-x-4 bg-opacity-50  rounded-md text-black p-8 text-xl font-medium">
        <li className="cursor-pointer px-4 py-2 hover:text-blue-500 hover:underline  rounded">Buy</li>
        <li className="cursor-pointer px-4 py-2 hover:text-blue-500 hover:underline rounded">Rent</li>
        <li className="cursor-pointer px-4 py-2 hover:text-blue-500 hover:underline rounded">Share</li>
        <li className="cursor-pointer px-4 py-2 hover:text-blue-500 hover:underline rounded">Sell</li>
      </ul>
      <div className="relative mt-4 pb-9 px-8">
        <div className="flex items-center bg-white rounded-md border border-gray-300">
          <svg
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            className="w-6 h-6 ml-2 text-gray-500"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M7.067 3.1A7.498 7.498 0 1112.933 16.9 7.498 7.498 0 017.067 3.1zM15.5 15.5l6 6"></path>
          </svg>
          <input
            type="text"
            placeholder="County, City, Town or Area"
            className="flex-1 p-2 border-none outline-none px-16 py-5"
          />
        </div>
        <div className="mt-5">
      
      <div className="relative flex items-center bg-white rounded-md border border-gray-300">
      
        <PiClockCounterClockwise className="w-6 h-6 ml-2 text-gray-500" />

        
        <input
          type="text"
          placeholder="Last search: Ireland "
          className="flex-1 p-4 pl-14 pr-10 py-5 border-none outline-none text-black font-semibold"
        />

        
        <IoIosArrowForward className="absolute right-3 w-6 h-6 text-gray-500" />
      </div>

      
      
    </div>
      </div>
    </div> */}
              {/* SEO Content Inside Hero */}
              <div className="mt-8 text-white">
                <h2 className="text-xl font-semibold mb-4">
                  Houses and Apartments for Sale on Fraps
                  <span className="block text-gray-200">
                    Find your next home on Ireland's favourite property site
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Browser />
      {/* <PropertyTools /> */}
      {/* <PopularAreas /> */}
    </div>
  );
}

export default Home;
