import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Header from "../Components/Header";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import Toast components
import "react-toastify/dist/ReactToastify.css"; // Import Toast
import { FaPaperPlane } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";

const PropertyDetail = () => {
  const { id } = useParams(); // Get property ID from URL params
  const [property, setProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: "",
  });

  // Fetch property details
  useEffect(() => {
    fetch(`https://propertybackend.myappsdevelopment.co.in/property/get-singleproperty/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProperty(data); // Set the fetched property data
        }
      })
      .catch((error) =>
        console.error("Error fetching property details:", error)
      );
  }, [id]);

  // Modal toggle functions
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://propertybackend.myappsdevelopment.co.in/email/send-email`, {
        recipientEmail: property.email,
        subject: "Property Inquiry",
        message: `
          <html>
          <body>
            <p><strong>Name:</strong> ${formData.name}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Mobile Number:</strong> ${formData.mobileNumber}</p>
            <p><strong>Message:</strong> ${formData.message}</p>
            <p><strong>I am interested in property</strong></p>
            <p><strong>Address:</strong> ${property.address}</p>
          </body>
          </html>
        `,
      });
      toast.success("Enquiry sent successfully!"); // Use toast for success
      closeModal();
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send enquiry. Please try again."); // Use toast for error
    }
  };

  if (!property) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    ); // Loading spinner
  }

  return (
    <div className="bg-white min-h-screen">
      <Header />
      <div className="max-w-5xl mx-auto mt-10 p-8 bg-white shadow-md rounded-lg border border-gray-200">
        {/* Property Title */}
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          {property.propertyType?.typeName} - {property.address}
        </h1>

        {/* Media Slider */}
        <div className="mt-8">
          {property.media && property.media.length > 0 ? (
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              className="rounded-lg overflow-hidden"
            >
              {property.media.map((url, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={url}
                    alt={`Property Media ${index + 1}`}
                    className="w-full h-80 object-cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>No Media Available</p>
          )}
        </div>

        {/* Property Details Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10">
          <div>
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Property Details
            </h2>
            <p className="text-gray-600 mb-6">{property.propertyDescription}</p>
            <div className="space-y-4">
              {property.price && (
                <div>
                  <strong>Price:</strong> €{property.price}
                </div>
              )}
              {property.bedrooms && (
                <div>
                  <strong>Bedrooms:</strong> {property.bedrooms}
                </div>
              )}
              {property.bathrooms && (
                <div>
                  <strong>Bathrooms:</strong> {property.bathrooms}
                </div>
              )}
              {property.floorArea && (
                <div>
                  <strong>Floor Area:</strong> {property.floorArea}{" "}
                  {property.floorAreaUnit?.floorAreaUnitName || ""}
                </div>
              )}
              {property.auctionLocation && (
                <div>
                  <strong>Location:</strong> {property.auctionLocation}
                </div>
              )}
              {property.BER && (
                <div>
                  <strong>BER:</strong> {property.BER} (
                  {property.BERNumber || "N/A"})
                </div>
              )}
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Contact Information
            </h2>
            <ul className="space-y-2 text-gray-600">
              <li>
                <strong>Full Name:</strong> {property.fullName}
              </li>
              <li>
                <strong>Email:</strong> {property.email}
              </li>
              <li>
                <strong>Phone:</strong> {property.phoneNumber}
              </li>
              <li>
                <strong>Receive Enquiries Via:</strong>{" "}
                {property.receiveEnquiriesVia}
              </li>
              <li>
                <strong>Call Between:</strong> {property.callBetween}
              </li>
            </ul>
          </div>
        </div>

        {/* Features and Facilities Section */}
        <div className="mt-10">
          {(property.propertyFeatures?.length > 0 ||
            property.facilities?.length > 0) && (
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Features & Facilities
            </h2>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {property.propertyFeatures?.length > 0 && (
              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                  Features
                </h3>
                <ul className="list-disc list-inside text-gray-600">
                  {property.propertyFeatures &&
                  property.propertyFeatures.length > 0 ? (
                    (() => {
                      try {
                        // Clean up the string and parse it
                        const cleanString =
                          property.propertyFeatures[0].replace(
                            /[\[\]";\n]/g,
                            ""
                          );
                        const featuresArray = cleanString
                          .split(",")
                          .map((feature) => feature.trim());
                        return featuresArray.length > 0 ? (
                          featuresArray.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))
                        ) : (
                          <li>N/A</li>
                        );
                      } catch (error) {
                        console.error(
                          "Error parsing property features:",
                          error
                        );
                        return <li>Error loading features</li>;
                      }
                    })()
                  ) : (
                    <li>N/A</li>
                  )}
                </ul>
              </div>
            )}
            {property.facilities?.facilityName && (
              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                  Facilities
                </h3>
                <ul className="list-disc list-inside text-gray-600">
                  <li>{property.facilities.facilityName}</li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Enquiry Button */}
        <div className="mt-10">
          <button
            onClick={openModal}
            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center"
          >
            <FaRegQuestionCircle className="mr-2" />{" "}
            {/* Icon with margin for spacing */}
            Make an Enquiry
          </button>
        </div>

        {/* Enquiry Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="relative bg-white p-6 rounded-lg shadow-md w-96">
              <div className="absolute top-2 right-2">
                <IconButton
                  onClick={closeModal}
                  style={{ color: "red" }} // Make the icon red
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Make an Enquiry
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="mobileNumber"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center"
                >
                  <FaPaperPlane className="mr-2" />{" "}
                  {/* Icon with margin for spacing */}
                  Send Enquiry
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default PropertyDetail;
