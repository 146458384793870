import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; // Eye icons for password visibility toggle
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignIn = () => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // For toggling password visibility
  const navigate = useNavigate();

  const handleGoogleSignIn = () => {
    window.location.href = "https://propertybackend.myappsdevelopment.co.in/auth/google";
  };

  const handleSignIn = async () => {
    try {
      const response = await axios.post("https://propertybackend.myappsdevelopment.co.in/auth/login", {
        email: emailOrUsername,
        password: password,
        withCredentials: true,
      });

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        toast.success("Login successful!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
        navigate("/");
      } else {
        toast.error("Invalid login credentials. Please try again.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(
        "Login failed. Please check your credentials and try again.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
        }
      );
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="flex flex-col min-h-screen bg-background p-4 px-[550px] py-[100px]">
      <ToastContainer /> {/* Toast container to display notifications */}
      <img src="/logo1.png" alt="Daft Logo" className="mb-6 w-20 h-14" />
      <h1 className="text-2xl text-zinc-800">Sign in to continue</h1>
      {/* Google Sign-In Button */}
      <button
        onClick={handleGoogleSignIn}
        className="flex items-center justify-around w-full p-3 mt-4 pr-20 bg-white border border-zinc-300 rounded-lg shadow-md hover:shadow-lg transition duration-200"
      >
        <FcGoogle className="text-3xl" />
        <p className="text-xl">Continue with Google</p>
      </button>
      <div className="flex items-center justify-center mt-4">
        <span className="text-zinc-500">or</span>
      </div>
      {/* Email/Username Input */}
      <label className="mt-4 font-semibold">Email or username</label>
      <input
        type="text"
        value={emailOrUsername}
        onChange={(e) => setEmailOrUsername(e.target.value)}
        className="w-full mt-1 p-3 border border-zinc-300 focus:outline-none focus:ring focus:ring-primary"
      />
      {/* Password Input with Eye Icon */}
      <label className="mt-4 font-semibold">Password</label>
      <div className="relative w-full mt-1">
        <input
          type={passwordVisible ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-3 border border-zinc-300 focus:outline-none focus:ring focus:ring-primary"
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-3 cursor-pointer text-zinc-600"
        >
          {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
        </span>
      </div>
      {/* Sign-In Button */}
      <button
        onClick={handleSignIn}
        className="w-full mt-4 border border-zinc-300 bg-[#4170c4] text-white rounded-lg p-4 hover:bg-primary/80"
      >
        SIGN IN
      </button>
      <hr className="mt-9" />
      <div className="mt-6 text-sm text-black flex justify-between">
        <span className="text-2xl">No account yet?</span>
        <Link
          to="/signup"
          className="text-primary hover:underline text-[#4170c4] font-semibold"
        >
          Sign up for Fraps
        </Link>
      </div>
    </div>
  );
};

export default SignIn;
