import React from "react";
import Header from "../Components/Header";

const AccountSetting = () => {
  return( 
  
  <div>
  <Header />
  </div>
  );
};
export default AccountSetting;
